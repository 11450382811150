.carouselCaption {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.carouselItem {
  border-radius: 10px;
  justify-content: center;
}

img {
  border-radius: 10px;
  // max-width:600px;

  object-fit: cover;
  width: 100%;
  height: 100%;
  // justify-self: center;
}

.row {
  height: 100%;
  justify-content: space-around;
}

.wrapperCard {
  display: flex;
  flex-direction: column;
}

.card {
  margin: 15px;
  min-height: 300px;
  min-width: 300px;
  background-color: #53e3a6;
  display: flex;
  padding: 10px;
}

.card-count {
  justify-content: center;
}

.col {
  justify-content: center;
  flex-grow: unset!important;
  color: white;
}

.ql-editor {
  font-size: x-large;
}

.quill {
  height: 100%;
}
