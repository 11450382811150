#navbar {
  background-color: #53e3a6!important;
  border-width: 2px!important;
  border-color: rgba(0, 0, 0, 50%)!important;
}

.name, .fa, .label {
  color: white!important;
}

.logoutButton {
  background-color: transparent;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 50%)!important;
  &:hover {
    background-color: rgba(255, 255, 255, 50%)!important;
  }
}