.form-control.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  font-weight: 200;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
  font-weight: 200;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
  font-weight: 200;
}

#formBasicPassword,
#formBasicUsername {
  border-radius: 10px;
  border-width: 2px;
  border-color: white;
  color: white;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: 900;
  transition-duration: 0.25s;
  transition-property: max-width, width, min-width, color;
  max-width: 500px;
  min-width: 250px;
  width: 60%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  &:focus {
    background-color: white;
    color: #53e3a6;
    max-width: 550px;
    min-width: 300px;
    width: 70%;
  }
}

.loginButton {
  background-color: white;
  color: #53e3a6;
  border-width: 0px;
  max-width: 500px;
  min-width: 250px;
  width: 60%;

  &:hover {
    background-color: white;
    opacity: 0.8;
    color: #53e3a6;
  }
  &:focus {
    background-color: white;
    color: #53e3a6;
  }
}

@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Exo", sans-serif;
}

.formWrapper,
.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.context {
  width: 100%;
  position: absolute;
  top: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 1;
  color: white;
}

.disappear {
  display: none;
}

.area {
  background: -webkit-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
  background: -moz-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
  background: -o-linear-gradient(top left, #50a3a2 0%, #53e3a6 100%);
  background: linear-gradient(to bottom right, #50a3a2 0%, #53e3a6 100%);
  width: 100%;
  height: 100vh;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
